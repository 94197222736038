<template>
  <div style="{ paddingTop: '20px' }">
    <h4>focusable without pagination</h4>
    <ModernTable
      :columns="[
        {
          field: 'lotNumber',
          label: 'Lot #',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'name',
          label: 'Name',
          sortable: true,
          searchable: true
        },
        {
          field: 'address',
          label: 'Address',
          sortable: true,
          searchable: true
        }
      ]"
      :rows="rows"
      :filters="{
        show: true
      }"
      :focusable="true"
      :selected.sync="selected"
    />
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table.vue'

export default {
  components: {
    ModernTable
  },
  data() {
    return {
      selected: null,
      rows: (() => {
        const rows = []
        for (let a = 1; a <= 40; a++) {
          rows.push({
            lotNumber: a,
            name: a,
            address: a
          })
        }

        return rows
      })()
    }
  }
}
</script>
