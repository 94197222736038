var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ padding: '200px 100px' })},[_c('div',[_c('h4',[_vm._v("Empty Table")]),_c('ModernTable',{attrs:{"columns":[
        {
          field: 'lotNumber',
          label: 'Lot #',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'name',
          label: 'Name',
          sortable: true,
          searchable: true
        },
        {
          field: 'address',
          label: 'Address',
          sortable: true,
          searchable: true
        },
        {
          field: 'status',
          label: 'Status'
        }
      ],"rows":[],"filters":{
        show: true
      }}})],1),_c('FocusableWithPagination'),_c('FocusableWithoutPagination'),_c('Expandable')],1)}
var staticRenderFns = []

export { render, staticRenderFns }