<template>
  <div :style="{ padding: '200px 100px' }">
    <div>
      <h4>Empty Table</h4>
      <ModernTable
        :columns="[
          {
            field: 'lotNumber',
            label: 'Lot #',
            numeric: true,
            sortable: true,
            searchable: true
          },
          {
            field: 'name',
            label: 'Name',
            sortable: true,
            searchable: true
          },
          {
            field: 'address',
            label: 'Address',
            sortable: true,
            searchable: true
          },
          {
            field: 'status',
            label: 'Status'
          }
        ]"
        :rows="[]"
        :filters="{
          show: true
        }"
      />
    </div>

    <FocusableWithPagination />
    <FocusableWithoutPagination />
    <Expandable />
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table.vue'

import FocusableWithPagination from './examples/FocusableWithPagination'
import FocusableWithoutPagination from './examples/FocusableWithoutPagination'
import Expandable from './examples/Expandable'

export default {
  components: {
    ModernTable,

    FocusableWithPagination,
    FocusableWithoutPagination,
    Expandable
  }
}
</script>
