<template>
  <div style="{ paddingTop: '20px' }">
    <h4>expandable, focusable, and paginated rows</h4>
    <ModernTable
      :columns="[
        {
          field: 'lotNumber',
          label: 'Lot #',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'name',
          label: 'Name',
          sortable: true,
          searchable: true
        },
        {
          field: 'address',
          label: 'Address',
          sortable: true,
          searchable: true
        },
        {
          field: 'button',
          label: 'Button'
        }
      ]"
      :rows="rows"
      :filters="{
        show: true
      }"
      :pagination="{
        perPage: 10
      }"
      :expandableRows="true"
      :focusable="true"
      :selected.sync="selected"
    />
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table.vue'

import Button from './Button'
import Details from './Details'

export default {
  components: {
    ModernTable
  },
  data() {
    return {
      selected: null,
      rows: (() => {
        const rows = []
        for (let a = 1; a <= 40; a++) {
          rows.push({
            // "_expandable" needs to be the first key in the object
            // so that the row is rendered correctly
            _expandable: {
              // whether or not to show the expandable icon
              show: a === 1 || a === 11 || a === 12,

              // the component to show when the expandable icon is pressed
              component: Details,

              // props to send to the expandable icon
              props: {}
            },
            lotNumber: a,
            name: String.fromCodePoint(a + 64),
            address: a,
            button: {
              component: Button,
              props: {
                text: 'button'
              }
            }
          })
        }

        return rows
      })()
    }
  },
  watch: {
    selected: (selected, prev) => {
      console.log('name selected', {
        current: selected.name,
        previous: prev ? prev.name : null
      })
    }
  }
}
</script>
