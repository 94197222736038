var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"{ paddingTop":"'20px' }"}},[_c('h4',[_vm._v("focusable without pagination")]),_c('ModernTable',{attrs:{"columns":[
      {
        field: 'lotNumber',
        label: 'Lot #',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',
        label: 'Address',
        sortable: true,
        searchable: true
      }
    ],"rows":_vm.rows,"filters":{
      show: true
    },"focusable":true,"selected":_vm.selected},on:{"update:selected":function($event){_vm.selected=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }